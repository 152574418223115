.main{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background-image: url('../img/metametaverse.png');
    background-repeat: no-repeat;
    background-position: top;
}

.main-bg{
    background-image: linear-gradient(90deg, rgb(98 0 255 / 35%), #040305);
}

.input_field{
    width: 100%;
    height: 2.6em;
    margin-bottom: 10px;
    padding: 0.8em 5em 0.8em 1.4em;
    border-style: solid;
    border-width: 2px;
    background-color: transparent;
    box-shadow: 0 0 1em 0 transparent;
    transition: all 500ms ease;
    color: #02ffc1;
    font-size: 1em;
    line-height: 1;
    font-weight: 300;
}


input:invalid {
    border: 2px dashed red;
}

.input-error{
    font-size: 12px;
    color: red;
    display: block;
    text-align: start;
    margin: 0 0 8px 0;
}


@media screen and (max-width: 540px) {
    .input_field {
        width: 80%;
    }
    .input-error{
        text-align: center;
    }
}

.cta.cta__sub {
    height: 3em;
}

.cta {
    position: relative;
    z-index: 3;
    height: 2.6em;
    min-width: 11em;
    padding: 0.78em 1em;
    background-color: transparent;
    background-image: url('https://assets-global.website-files.com/620a6548cad9c54e2a5ca153/620ae872d7bbd78a826836a0_main.svg');
    background-position: 50% 50%;
    background-size: 100%;
    background-repeat: no-repeat;
    text-align: center;
}


.w-button {
    display: inline-block;
    padding: 9px 15px;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
}



.main .bg-bottom{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 15px;
}

.main .bg-bottom img{
    max-width: 100%;
}

.main .main-title{
    height: 100%;
}
.main .main-title .container{
    max-width: 1400px;
    margin: 0 auto;
    height: 100%;
}

.title-items{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(0%);
}

.title-content{
    padding: 35px;
    max-width: 60%;
    transition: 1s ease;
    font-family: 'MavenPro-SemiBold';
}

.title-content .title h3{
    text-transform: uppercase;
    font-weight: bold;
    padding: 30px 0 20px 0;
   
}

.title-content .title h3 span{
    color: #02ffc1;
}


.main .main-title{
    color: rgb(255, 255, 255);
    z-index: 30;
    text-align: center;
}



.title-content{
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}


  
.main .title-content h3{
    font-size: 20px;
}
.main .main-title p{
    font-size: 20px;
    font-weight: 400;
    padding: 25px 0;
}

.result .winner{
    color: #02ffc1;
}

.result .lost{
    color: #ff0000;
}

  @media screen and (max-width: 1024px) {
    .title-content{
        padding: 30px;
    }
    .main .main-title p{
        font-size: 16px;
        padding: 10px 0;
    }
  }

  @media screen and (max-width: 910px) {

    .main .main-title p{
    font-size: 16px;
    font-weight: 400;
    padding: 6px 0px 40px;
    }
  }

  @media screen and (max-width: 838px) {
    .main{
        height: 660px;
    }

      .main .title-content{
          padding: 0;
      }

      .main .title-content h3{
        font-size: 27px;
      }
      .main .main-title p{
        font-size: 14px;
        font-weight: 400;
        padding: 0px 3px 26px;
      }
  }


  @media screen and (max-width: 768px) {
    .main{
        height: 660px;
    }

      .main .title-content{
          padding: 0;
      }

      .main .title-content h3{
        font-size: 27px;
      }
      .main .main-title p{
        font-size: 14px;
        font-weight: 400;
        padding: 0px 3px 26px;
      }
  }

  @media screen and (max-width: 738px) {
  
      .main .title-content h3{
        font-size: 24px;
      }
      .main .title-content h1{
          font-size: 45px;
      }
    
  }

  @media screen and (max-width: 667px) {
    .main .main-title h1{
        font-size: 40px;
        font-weight: 900;
        margin: 0;
        padding-bottom: 0;
    }
    .main .main-title h3{
        font-size: 18px;
    }
    .main .main-title p{
        font-size: 15px;
        font-weight: 400;
        padding: 10px 30px 15px;
    }
}

@media screen and (max-width: 596px) {
    .main .main-title h1{
        font-size: 38px;
        font-weight: 900;
        margin: 0;
        padding-bottom: 0;
    }
    .main .main-title h3{
        font-size: 18px;
    }
    .main .main-title p{
        font-size: 15px;
        font-weight: 400;
        padding: 10px 30px 15px;
    }
}


@media screen and (max-width: 567px) {
    .main .main-title h3{
        font-size: 18px;
    }
    .main .main-title p{
        font-size: 15px;
        font-weight: 400;
        padding: 10px 30px 15px;
    }
}

@media screen and (max-width: 540px) {
    .main{
        height: 100vh;
    }
    .title-content{
        max-width: 100%;
    }
    .main .main-title h3{
        width: 80%;
        margin: 0 auto;
    }

    .main .main-title p{
        padding: 10px 87px 15px;
    }
}


@media screen and (max-width: 425px) {

    .main .main-title h3{
        font-size: 18px;
    }
    .main .main-title p{
        font-size: 15px;
        font-weight: 400;
        padding: 10px 74px 15px;
    }
}

@media screen and (max-width: 402px) {

    .main .main-title h3{
        font-size: 22px;
    }
    .main .main-title p{
        font-size: 14px;
        padding: 10px 40px 15px;
       
    }
}

@media screen and (max-width: 375px) {

    .main .main-title h3{
        font-size: 19px;
    }
    .main .main-title p{
        font-size: 14px;
        padding: 10px 40px 15px;
       
    }
}

@media screen and (max-width: 367px) {

    .main .main-title h3{
        font-size: 19px;
    }
    .main .main-title p{
        font-size: 12px;
       
    }
}

@media screen and (max-width: 320px) {

    .main .main-title h3{
        font-size: 19px;
    }
    .main .main-title p{
        font-size: 12px;
       
    }
}